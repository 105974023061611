<template>
  <!-- 报表查询 -->
  <div id="report">
    <!-- 左侧树 -->
    <app-tree @select="onSelect"></app-tree>
    <!-- 右侧内容 -->
    <div class="content">
      <!-- 顶部title -->
      <h3 class="title">
        <!-- {{ $store.state.parentData.name }} - -->
        <!-- {{ $store.state.selectedData.name }} -->

        <span v-if="parentTitle">{{ parentTitle }}</span>-<span v-if="title">{{ title }}</span>
      </h3>
      <!-- 查询区域 -->
      <div class="search-criteria">
        <a-space :size="size">
          <a-form :form="form" layout="inline" @submit="handleSubmit">
            <a-form-item label="数据类型">
              <a-select v-decorator="['TJLX']" style="width: 100px" @change="handleChange">
                <a-select-option value="4"> 年报表 </a-select-option>
                <a-select-option value="2"> 月报表 </a-select-option>
                <a-select-option value="1"> 日报表 </a-select-option>
                <a-select-option value="5"> 分钟数据 </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="TJLX != 1&&TJLX != 5" label="年份">
              <a-select v-decorator="['Year']" style="width: 100px">
                <a-select-option v-for="item in yearData" :key="item" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="TJLX == 2" label="月份">
              <a-select v-decorator="['Month']" style="width: 100px">
                <a-select-option v-for="item in monthData" :key="item" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-show="TJLX == 1||TJLX == 5" label="日期">
              <a-date-picker @change="onChange" v-decorator="['Date']" :format="dateFormat" />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
              <a-button style="margin-left: 20px" :size="size" type="primary" html-type="submit">
                查询
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button style="margin-left: 20px" type="primary" @click="onExport">
                导出
              </a-button>
            </a-form-item>
          </a-form>
        </a-space>
      </div>
      <div class="table-shell">
        <div :class="['header', currentStationIsTingYun ? 'header450' : '']">
          <p class="label">报警颜色</p>
          <div class="classification">
            <div class="icon icon1"></div>
            <span>异常</span>
          </div>
          <div class="classification">
            <div class="icon icon2"></div>
            <span>超标</span>
          </div>
          <div class="classification">
            <div class="icon icon3"></div>
            <span>预警</span>
          </div>
          <div v-show="currentStationIsTingYun" style="margin-left: 30px">
            状态：
            <a-dropdown class="statusMenu">
              <a @click="(e) => e.preventDefault()"><span style="color: red; cursor: pointer">停运(时段)</span></a>
              <a-menu slot="overlay" class="statusMenu">
                <a-menu-item class="statusMenu" style="background: #fff !important; padding: 15px">
                  <a-table :columns="columnsSd" :data-source="dataSd" :pagination="false" bordered :row-key="
                      (r, i) => {
                        return i.toString();
                      }
                    " size="small" class="timetable" :loading="loadingSd">
                  </a-table>
                  <div v-show="totalSd > 10" style="
                      display: flex;
                      padding-bottom: 10px;
                      margin-top: 10px;
                    ">
                    <div style="flex: 1"></div>
                    <a-pagination show-quick-jumper :page-size="pageSizeSd" v-model="currentSd" :total="totalSd"
                      @change="onChangeSdPage" />
                  </div>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>

        <!-- 内容展示区 -->
        <div class="table-content">
          <!-- table表格 -->
          <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" :row-key="
              (r, i) => {
                return i.toString();
              }
            " bordered size="small" :scroll="tableScroll">
            <span slot="filterfff" slot-scope="text" v-html="formatTable(text)">
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppTree from "@/components/AppTree.vue";
  import {
    http
  } from "../../utils/Http.js";
  import moment from "moment";
  import dayjs from "dayjs";
  const columnsSd = [{
      dataIndex: "TYSD",
      key: "TYSD",
      width: 200,
      align: "center",
      title: "停运时段",
    },
    {
      dataIndex: "TYYY",
      key: "TYYY",
      width: 180,
      align: "center",
      title: "停运原因",
    },
  ];
  // const data = [];
  // for (let i = 0; i < 10; i++) {
  // 	data.push({
  // 		key: i,
  // 		name: "John Brown",
  // 		age: i + 1,
  // 		street: "Lake Park",
  // 		building: "C",
  // 		number: 2035,
  // 		companyAddress: "Lake Street 42",
  // 		companyName: "SoftLake Co",
  // 		gender: "M",
  // 		loading: false // table 加载页面loading页面。
  // 	});
  // }

  export default {
    data() {
      return {
        size: "default",
        dateFormat: "YYYY-MM-DD",
        form: this.$form.createForm(this, {
          name: "coordinated",
        }),

        TJLX: "1", // 数据类型。1：日。2：月。4：年。
        yearData: [],
        monthData: [],
        currentMonth: new Date().getMonth() + 1,

        // table 相关
        data: [],
        columns: [],
        loading: false,
        columnsSd: columnsSd,
        currentStationIsTingYun: false, //当前基站是否停运
        pageSizeSd: 10, //时段
        currentSd: 1,
        totalSd: 0,
        dataSd: [],
        loadingSd: false,

        dayDate: dayjs(new Date()).format("YYYY-MM-DD"),

        parentTitle: "",
        title: "",
      };
    },

    components: {
      AppTree,
    },
    /**
     * 计算属性
     */
    computed: {
      tableScroll() {
        return {
          x: "800px",
          y: "calc(100vh - 380px)"
        };
        //  return {
        //     x: "800px",
        //     y: 0,
        //   };
      },
    },
    filters: {
      fromcolor(data) {
        return 1;
        //         let date = new Date(time);
        //         return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      },
    },
    created() {
      var me = this;
      var nian = [];
      for (var i = 0; i < 5; i++) {
        nian.push(new Date().getFullYear() - i);
      }
      this.yearData = nian;

      var monthData = [];
      for (var i = 0; i < 12; i++) {
        monthData.push(i + 1);
      }
      this.monthData = monthData;
    },

    mounted() {
      var me = this;
      this.form.setFieldsValue({
        TJLX: "1",
      });

      this.form.setFieldsValue({
        Year: me.yearData[0],
      });

      this.form.setFieldsValue({
        Month: me.currentMonth,
      });

      this.form.setFieldsValue({
        Date: moment(me.$store.state.JSSJ),
      });

      me.HJJC_ALARM_RECORD_STATE();
    },

    methods: {
      moment,
      //分页
      onChangeSdPage(pageNumber) {
        let me = this;
        me.currentSd = pageNumber;
        me.getStopTime();
      },
      onChange(sender, value) {
        this.dayDate = value;
      },

      //数据类型
      handleChange(value) {
        this.TJLX = value;
      },

      /**
       * 导出数据的点击事件
       */
      onExport() {
        let me = this;
        let RQ = "";
        this.form.validateFields((err, values) => {
          RQ = values.Date._i;
          // 年报表 4
          // 月报表 2
          // 日报表 1
          if (this.TJLX == 1||this.TJLX == 5) {
            RQ = this.dayDate;
          } else if (this.TJLX == 2) {
            RQ = values.Year + "-" + values.Month;
          } else if (this.TJLX == 4) {
            RQ = values.Year;
          }
        });
        let QueryCondition =
          `${me.$store.state.loggedIn.CKM},${this.$store.state.selectedKeys},${me.TJLX},${RQ},${this.$store.state.expandedKeys}`;
        console.log(QueryCondition);

        let HTTPURL = this.$store.state.HTTPURL;
        console.log(HTTPURL);

        window.open(
          "" +
          HTTPURL +
          "/data/RPT_GetPrintExcelFile_DynData.aspx?Module=SP&UID=admin&PID=&Template=104C4B0D23F6410B91A2764DBBHJJCCS&QueryCondition=" +
          QueryCondition +
          ""
        );
      },

      //选择时效性
      onSelect(item) {
        // // // // // // // console.log(item);
        let me = this;
        if (item.Lx == 6) {
          me.parentTitle = this.$store.state.parentData.name;
          me.title = this.$store.state.selectedData.name;
          this.HJJC_ALARM_RECORD_STATE();
        }
      },

      dateChange(value) {
        // // // // // // // console.log(value);
      },

      //确定
      handleSubmit(e) {
        e.preventDefault();
        this.HJJC_ALARM_RECORD_STATE();
      },

      /***
       * getStopTime 获取停运时段
       */
      getStopTime() {
        let me = this;
        this.form.validateFields((err, values) => {
          // // // // // // // console.log(values);
          let me = this;

          var RQ = values.Date._i;
          // 年报表 4
          // 月报表 2
          // 日报表 1
          // 分钟数据 5
          if (this.TJLX == 1||this.TJLX == 5) {
            // RQ = values.Date._i;
            RQ = dayjs(values.Date).format("YYYY-MM-DD");
          } else if (this.TJLX == 2) {
            RQ = values.Year + "-" + values.Month;
          } else if (this.TJLX == 4) {
            RQ = values.Year;
          }
          var params = {
            CKM: this.$store.state.loggedIn.CKM,
            SFBH: "",
            SBH: "",
            XBH: "",
            JZMC: "",
            station_bh: this.$store.state.selectedKeys,
            SXZM: "",
            KSSJ: RQ,
            JSSJ: RQ,
            PAGESIZE: me.pageSizeSd,
            PAGENUMBER: me.currentSd,
          };

          me.loadingSd = true;
          me.dataSd = [];

          // // // // // // // console.log(params);
          http({
            url: "/data/q30/HJJC_STATIONLIST_OUTAGE_LIST.aspx",
            data: params,
            success(obj) {
              console.log(obj);
              me.loadingSd = false;
              if (obj.Data && Array.isArray(obj.Data)) {
                me.dataSd = obj.Data;
              }
            },
            failure(obj) {
              me.loadingSd = false;
            },
            me,
          });
        });
      },

      /**
       * 请求接口进行获取数据
       */
      HJJC_ALARM_RECORD_STATE() {
        var me = this;

        this.form.validateFields((err, values) => {
          // // // // // // // console.log(values);
          let me = this;

          var RQ = values.Date._i;
          // 年报表 4
          // 月报表 2
          // 日报表 1 分钟数据5
          if (this.TJLX == 1||this.TJLX == 5) {
            // RQ = values.Date._i;
            RQ = dayjs(values.Date).format("YYYY-MM-DD");
          } else if (this.TJLX == 2) {
            RQ = values.Year + "-" + values.Month;
          } else if (this.TJLX == 4) {
            RQ = values.Year;
          }
          var params = {
            CKM: this.$store.state.loggedIn.CKM,
            station_bh: this.$store.state.selectedKeys,
            TJLX: this.TJLX,
            RQ: RQ,
            EnterpriseBH: this.$store.state.expandedKeys,
          };

          me.loading = true;
          me.data = [];
          me.currentStationIsTingYun = false;
          // // // // // // // console.log(params);
          http({
            url: "/data/q30/HJJC_DATA_REPORT_V2.aspx",
            data: params,
            success(obj) {
              console.log(obj);
              me.loading = false;
              if (obj.Data5 && Array.isArray(obj.Data5)) {
                if (obj.Data5.length > 0 && obj.Data5[0].TS == 1) {
                  me.currentStationIsTingYun = true;
                  me.getStopTime();
                } else {
                  me.currentStationIsTingYun = false;
                }
              }
              me.columns = me.formatColumns(obj); // 格式化columns数据
              console.log(me.columns);
              me.data = me.formatData(obj); // 格式化data数据
            },
            failure(obj) {
              me.loading = false;
            },
            me,
          });
        });
      },

      /**
       * 格式化columns 表头
       * obj：全部的ajax返回的数据
       */
      formatColumns(obj) {
        let {
          Data,
          Data1
        } = obj;
        if (!Data && !Data1) return "";
        let tree = [];
        Data.map((p) => {
          p.title = p.SC;
          p.align = "center";

          p.children = Data1.filter((c) => {
            if (p.ItemCode == c.ItemCode) {
              if (c.ACTUAL_UNIT == "" || c.ACTUAL_UNIT == null) {
                c.title = c.ZT;
              } else {
                c.title = c.ZT + "(" + c.ACTUAL_UNIT + ")";
              }
              c.width = 128;
              c.dataIndex = c.MC;
              c.key = c.MC;
              c.align = "center";
              c.scopedSlots = {
                customRender: "filterfff"
              };
              return c;
            }
          });
          if (p.children && p.children.length > 0) {
            tree.push(p)
          }
          return p;
        });
        tree.unshift({
          title: "时间",
          dataIndex: "HOUR",
          width: 128,
          align: "center",
        });
        return tree;
      },

      /**
       * 格式化 data 数据
       * obj：全部的ajax返回的数据
       */
      formatData(obj) {
        let me = this;
        // Data2:详细信息。Data3：平均/最大/最小/样本。Data4：排放总量。
        let {
          Data2,
          Data3,
          Data4
        } = obj;
        // 创建一个对象，包含所有的key，值全部是0。
        let key = Object.keys(Data2[0]),
          d4 = {};
        // // // // // console.log(key);
        key.map((item) => {
          d4[item] = 0;
        });

        // // // // // console.log(d4);
        if(me.TJLX!=5){
           d4 = Object.assign(d4, Data4[0]);
        }

        let newData2 = me.getNewObj(Data2);
        let newData3 = me.getNewObj(Data3);
        let newData4 = [];
        if(me.TJLX!=5){
          newData4=me.getNewObj([d4]);
        }
        // // // // console.log(newData2)
        // console.log(newData3)
        // // // // console.log(newData4)
        if(me.TJLX!=5){//分钟数据为5  不要平均值 下面这些值
          newData3.forEach((item) => {
          if (item.LX.split("|||")[0] == 1) {
            item.HOUR = "平均值";
          }
          if (item.LX.split("|||")[0] == 2) {
            item.HOUR = "最大值";
          }
          if (item.LX.split("|||")[0] == 3) {
            item.HOUR = "最小值";
          }
          if (item.LX.split("|||")[0] == 4) {
            item.HOUR = "样本数";
          }
          });
          newData4[0].HOUR = "排放总量";
        }

        let data = [];
        if(me.TJLX==5){
          data = [...newData2];
        }else{
          data=[...newData2, ...newData3, ...newData4];
        }
        // let data = [...Data2, ...Data3, d4];
        console.log(data);
        return data;
      },
      getNewObj(list) {
        // // // // // console.log(list)
        let newList = [];
        list.forEach((item) => {
          let newObj = {};
          for (let i in item) {
            // // // // // // // console.log(i);
            // // // // // // // console.log(item[i]);
            // 先判断是否“ZT”结尾
            if (i == "HOUR") {
              newObj[i] = item[i];
            } else if (i.indexOf("ZT") == -1) {
              // // console.log((typeof item[i+"ZT"]) == "undefined");
              newObj[i] = (item[i] || "") + "|||" + item[i + "ZT"];
            }
          }
          // // // // console.log(newObj);
          newList.push(newObj);
        });
        // // // // // console.log(newList);
        return newList;
      },

      /**
       * 格式化table的数据展示。
       */
      formatTable(text) {
        let me = this;
        // // // console.log(text);
        if (typeof text == "string") {
          let t = text.split("|||")[0],
            start = text.split("|||")[1];
          // // // // console.log(t);

          if (start == 1 || start == "1") {
            return `<span style="color:#884898; font-weight:700;">${t}</span>`;
          } //异常
          if (start == 2) {
            return `<span style="color:#e22222; font-weight:700;">${t}</span>`;
          } //超标
          if (start == 3) {
            return `<span style="color:#f67c49; font-weight:700;">${t}</span>`;
          } //预警
          return `<span >${t}</span>`;
        } else {
          return `<span >${text.split("|||")[0]}</span>`;
        }
      },
    },

    destoryed() {
      this.loading = false;
    },
  };
</script>
<style lang="scss">
  .statusMenu {
    .ant-table-small>.ant-table-content>.ant-table-body {
      margin: 0px !important;
    }
  }
</style>

<style lang="scss" scoped>
  #report {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 30px;
    height: 100%;

    .tree {
      width: 248px;
      height: 100%;
      padding: 20px 10px 0 0;
      margin: 0 10px 0 0;
      overflow: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #ffffff;

      #tree {
        overflow: hidden;
      }
    }

    .tree::-webkit-scrollbar {
      width: 5px;
      background-color: #d9d9d9;
    }

    .tree::-webkit-scrollbar-thumb {
      height: 30px;
      background-color: #52c41a;
    }

    .content {
      flex: 1;
      // width: 900p;
      background-color: #ffffff;
      overflow: hidden;

      .title {
        text-align: center;
        font-size: 24px;
      }

      .search-criteria {
        // display: flex;
        padding: 0 20px;
      }

      .table-shell {
        width: 100%;
        padding-top: 10px;
        background-color: #ffffff;

        .header {
          display: flex;
          align-items: center;
          width: 330px;
          margin: 0 auto;
          font-size: 16px;
          padding-bottom: 20px;

          .label {
            margin-right: 30px;
          }

          .classification {
            display: flex;
            align-items: center;
            margin-right: 20px;

            .icon {
              width: 16px;
              height: 16px;
              border-radius: 5px;
              margin-right: 10px;
            }

            .icon1 {
              background-color: #884898;
            }

            .icon2 {
              background-color: #e22222;
            }

            .icon3 {
              background-color: #f67c49;
            }
          }
        }

        .header450 {
          width: 490px;
        }

        .table-content {
          // width: 100%;
          min-height: 792px;
          padding: 20px;
          border-top: 1px solid #e6f7ff;
        }
      }
    }
  }
</style>